
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { DetailedViewEnum, WidgetEnum } from '@/models/enums/WidgetEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import WidgetFactory from '@/models/Charts/widgetFactory';
import { CumulativeLinesGenerator } from '@/models/Charts/cumulativeLinesGenerator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isEmpty } from '@/utils/misc';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { CuttingTimePerGasData } from '@/models/Charts/chartsData';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { tenantsService } from '@/services/tenants.service';

@Component
export default class CutIntraday extends Vue {
  @Prop()
  private selectedDeviceIds!: string[];

  @Prop()
  private selectedShifts!: number[];

  @Prop()
  private onClose?: () => void;

  private modalOpen = true;

  @Inject('widgetDefinition')
  private widgetDefinition!: WidgetDefinition;

  private gasWidget = DetailedViewEnum.LaserTodaysGas;
  private cumulativeWidget = DetailedViewEnum.CumulativeCuttingTime;
  private cumulativeShiftsWidget = DetailedViewEnum.CumulativeCuttingTimePerShift;

  private gasGen: ChartGenerator<CuttingTimePerGasData[]> | null = null;
  private cumulativeGen: ChartGenerator<any[]> | null = null;
  private cumulativeShiftsGen: CumulativeLinesGenerator | null = null;

  private cumulativeLoaded = false;
  private cumulativeShiftsLoaded = false;
  private gasLoaded = false;

  private cumulativeOptions: any = {};
  private cumulativeShiftsOptions: any = {};
  private gasOptions: any = {};

  private thereIsCumulativeInfo = false;
  private thereIsCumulativeShiftsInfo = false;
  private thereIsGasInfo = false;

  private async mounted() {
    this.gasWidget =
      this.widgetDefinition.widget === WidgetEnum.LaserCuttingTime
        ? DetailedViewEnum.LaserTodaysGas
        : DetailedViewEnum.TubesCuttingTimePerGas;
    this.cumulativeWidget =
      this.widgetDefinition.widget === WidgetEnum.LaserCuttingTime
        ? DetailedViewEnum.CumulativeCuttingTime
        : DetailedViewEnum.TubesCumulativeCuttingTime;
    this.cumulativeShiftsWidget =
      this.widgetDefinition.widget === WidgetEnum.LaserCuttingTime
        ? DetailedViewEnum.CumulativeCuttingTimePerShift
        : DetailedViewEnum.TubesCumulativeCuttingTimePerShift;

    this.gasGen = WidgetFactory.createGenerator(this.gasWidget, this.tenantIdDh)!;
    this.cumulativeGen = WidgetFactory.createGenerator(this.cumulativeWidget, this.tenantIdDh)!;
    this.cumulativeShiftsGen = WidgetFactory.createGenerator(
      this.cumulativeShiftsWidget,
      this.tenantIdDh,
    ) as CumulativeLinesGenerator;

    await this.update();
  }

  private async update() {
    this.gasGen!.getData(this.selectedDeviceIds, this.selectedShifts, FilterTimeSpanEnum.Day).then(
      (gasData: CuttingTimePerGasData[] | null) => {
        if (!isEmpty(gasData)) {
          this.thereIsGasInfo = gasData!.some((d) => d.cutting_time > 0);
          const params = WidgetFactory.createOptions(this.gasWidget);
          this.gasOptions = this.gasGen?.updateOptions(gasData!, params);
        }
        this.gasLoaded = true;
      },
    );

    this.cumulativeGen!.getData(
      this.selectedDeviceIds,
      this.selectedShifts,
      FilterTimeSpanEnum.Day,
      FilterTimeAxisSpanEnum.None,
    ).then((cumulativeData: any[] | null) => {
      this.thereIsCumulativeInfo = !isEmpty(cumulativeData);
      if (this.thereIsCumulativeInfo) {
        const params = WidgetFactory.createOptions(this.cumulativeWidget);
        this.cumulativeOptions = this.cumulativeGen?.updateOptions(cumulativeData!, params);
      }
      this.cumulativeLoaded = true;
    });

    this.cumulativeShiftsGen!.getData(this.selectedDeviceIds, this.selectedShifts).then(
      (cumulativeShiftsData) => {
        this.thereIsCumulativeShiftsInfo = !isEmpty(cumulativeShiftsData);
        if (this.thereIsCumulativeShiftsInfo) {
          const params = WidgetFactory.createOptions(this.cumulativeShiftsWidget);
          this.cumulativeShiftsOptions = this.cumulativeShiftsGen?.updateOptions(
            cumulativeShiftsData!,
            params,
          );
        }
        this.cumulativeShiftsLoaded = true;
      },
    );
  }

  @Watch('modalOpen')
  private onModalOpenChanged() {
    if (!this.modalOpen && !!this.onClose) {
      this.onClose();
    }
  }

  private get tenantIdDh(): number {
    return tenantsService.store.currentIdDh()!;
  }

  private beforeDestroy() {
    this.gasGen?.abort();
    this.cumulativeGen?.abort();
    this.cumulativeShiftsGen?.abort();
  }
}
